<template>
  <div class="app-container">
    <div class="right_btns">
      <!-- <el-button type="primary" @click="shenbao" size="small" v-loading.fullscreen.lock="fullscreenLoading" plain>申报</el-button> -->
      <qzf-button jz="false" button_code="lskjbb_scbb" type="warning" @click="saveBiao" size="small" v-loading.fullscreen.lock="fullscreenLoading">取数</qzf-button>
      <el-button size="small" @click="printing()" type="success">打印</el-button>
      <!-- <qzf-button jz="false" button_code="lskjbb_dc" size="small" @click="downLoad()" type="primary">导出</qzf-button> -->
      <el-date-picker @change="chagePeriod" v-model="periodF" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-left:6px;"></el-date-picker>
      <!-- <el-date-picker
          v-model="periodF"
          type="month"
          placeholder="Pick a month"
           @change="chagePeriod"
        /> -->
    </div>
    <!-- 小企业 -->
    <div v-if="type == '小企业会计准则2013版'">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTable title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTable>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTable title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTable>
        </el-tab-pane>
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTable title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTable>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
      </el-tabs>
    </div>
    <!-- 小企业 -->
    <!-- 未执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则'">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTableTwo title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTableTwo>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTableTwo title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTableTwo>
        </el-tab-pane>
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTableTwo title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTableTwo>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
        <!-- 现金流量表 -->
        <!-- <el-tab-pane label="现金流量表" name="4">
          <cashTable ref="cash" typeName='历史数据'></cashTable>
        </el-tab-pane> -->
        <!-- 利润表季报 end -->
      </el-tabs>
    </div>
    <!-- 未执行新金融准则的一般企业 end -->
  </div>
</template>

<script>
import { durationOfTax } from "@/utils";
import balanceBusiness from '@/views/report/finance/tables/balanceBusiness' //民办非资产负债
import businessSheet from '@/views/report/finance/tables/businessSheet' //民办非业务活动表（月报）
import businessSeason from '@/views/report/finance/tables/businessSeason' //民办非业务活动表（季报）

import zcfzTable from '@/views/report/finance/tables/zcfzTable' //小企业资产负债表
import zcfzTableTwo from '@/views/report/finance/tables/zcfzTable2' //未执行新金融准则的一般企业的资产负债表
import zcfzTableThree from '@/views/report/finance/tables/zcfzTable3' //企业会计制度的资产负债表
import zcfzTableFour from '@/views/report/finance/tables/zcfzTable4' //已执行新金融准则的一般企业的资产负债表

import lrbTable from '@/views/report/finance/tables/lrbTable' //小企业利润表
import lrbTableTwo from '@/views/report/finance/tables/lrbTable2' //未执行新金融准则的一般企业的利润表
import lrbTableThree from '@/views/report/finance/tables/lrbTable3' //企业会计制度的利润表
import lrbTableFour from '@/views/report/finance/tables/lrbTable4' //已执行新金融准则的一般企业的利润表
import { parseTime } from '@/utils'
import { hisKjSave } from '@/api/old'
import { report,exportReport } from "@/api/printSet"

export default {
  name: 'historyFinance',
  components: { 
    balanceBusiness,
    businessSheet,
    businessSeason,
    zcfzTable,
    lrbTable,
    zcfzTableTwo,
    lrbTableTwo,
    zcfzTableThree,
    lrbTableThree,
    zcfzTableFour,
    lrbTableFour
  },
  data() {
    return {
      showStatus: false,
      fullscreenLoading: false,
      type: this.$store.getters["user/comInfo"].comKj,
      periodLrb: '',
      periodLrb2: '',
      durationOfTaxTime: '',
      activeName: '1',
      name: '资产负债表',
      qslyList: [],
      // comName: ''
      taxNo: '',
      contentStyleObj:{}, //高度变化
      periodF:this.$store.getters['user/comInfo'].period,
      period:this.$store.getters['user/comInfo'].period
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(356)
    window.addEventListener('resize', this.getHeight);
  },
  methods: {
    // 切换账期
    chagePeriod() {
      this.period = this.periodF
      if(this.activeName == 1){
        this.$refs.zcfz.getList(this.period)
      }else if(this.activeName == 2){
        this.$refs.lrb.getList(this.period)
      }else if(this.activeName == 3){
        this.$refs.lrbMon.getList(this.period)
      }
    },
    printing(){
      if(this.activeName == '1'){
        this.bookNames = 'kj_zcfz'
      }else if(this.activeName == '2'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'm'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    downLoad(){
      if(this.activeName == '1'){
        this.bookNames = 'gs_cwbb'
      }else if(this.activeName == '2'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'm'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },

    closeSubject() {
      this.showStatus = false
    },
    saveBiao(){
      if(!this.periodF){
        this.$message.error('请选择账期')
        return
      }
      this.fullscreenLoading = true;
      hisKjSave({period:this.period}).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("成功", 3)
          this.$refs.zcfz.getList()
          this.$refs.lrb.getList()
          this.$refs.lrbMon.getList()
        } 
        this.fullscreenLoading = false;
      })
    },
    handleClick(tab, event) {
      if(tab.props.label == '资产负债表'){
        this.name = tab.label
        this.$refs.zcfz.getList()
      }else if(tab.props.label == '利润表(季报)') {
        this.name = tab.label
        this.$refs.lrb.getList()
      }else if(tab.props.label == '利润表(月报)') {
        this.name = tab.label
        this.$refs.lrbMon.getList()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-container{
  position: relative;
  .right_btns{
    position: absolute;
    top: 14px;
    right: 40px;
    z-index: 99;
  }
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 13px;
    padding-right:20px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
